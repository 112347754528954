@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Neue Haas Grotesk Display";
  src: local("Neue Haas Grotesk Display"),
    url(./fonts/NeueHaasGrotDisp-55Roman.otf) format("opentype");
}

@font-face {
  font-family: "GT Alpina Fine Standard Regular Italic";
  src: local("GT Alpina Fine Standard Regular Italic"),
    url(./fonts/GT-Alpina-Fine-Standard-Regular-Italic.otf) format("opentype");
}

.type-serif {
  font-family: "GT Alpina Fine Standard Regular Italic";
}

.type-sans {
  font-family: "Neue Haas Grotesk Display";
}
